import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

function Logo(props) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        mainSiteUrl
                    }
                }
            }
        `
    );

    return <a href={site.siteMetadata.mainSiteUrl}>PLATO</a>;
}

export default Logo;
