import React from 'react';
import PubSub from 'pubsub-js';

class Price extends React.Component {
    state = {
        price: 1000
    };

    /**
     * HT Price of Table
     */
    getPrice(data) {
        const prices = {
            wood: { birch: { m2: 270, extra: 0 }, oak: { m2: 333, extra: 200 } },
            panel: [
                { length: 0, usage: 0.5 },
                { length: 1500, usage: 1.0 },
                { length: 2000, usage: 1.5 }
            ],
            height: [
                { height: 370, price: 120 },
                { height: 760, price: 0 },
                { height: 900, price: 170 },
                { height: 1070, price: 190 }
            ],
            gates: [0, 52, 104],
            plugs: [0, 106, 212],
            costs: { fix: 490 + 281 + 100, margin: 2.5 }
        };
        const maxLength = Math.max(data.width, data.length);
        const usage = prices.panel.reduce((p, c) => (maxLength > c.length ? c.usage : p), 0);
        const woodCost = prices.wood[data.material].m2 * usage + prices.wood[data.material].extra;
        const heightCost = prices.height.reduce((p, c) => (data.height === c.height ? c.price : p), 0);
        const tableCost = woodCost + heightCost + prices.gates[data.gates] + prices.plugs[data.plugs] + prices.costs.fix;
        const tableCostMarged = tableCost * prices.costs.margin;

        const price = Math.ceil(tableCostMarged / 10) * 10;
        return price;
    }

    /**
     * HT Price of Delivery, based on Cart
     */
    getDelivery(cart) {
        let price = 0;
        cart.map((item) => {
            price += item.quantity * Price.prototype.getPrice(item);
            return price;
        });
        price = parseInt(price * 0.009) * 10 - 1;
        if (price < 0) price = 0;
        return price;
    }

    /**
     * Total HT Price
     */
    getTotalPrice(cart) {
        let price = 0;
        cart.map((item) => {
            price += item.quantity * Price.prototype.getPrice(item);
            return price;
        });
        return price + this.getDelivery(cart);
    }

    /**
     * Ticket Object with everything needed
     */
    getTicket(cart, infos) {
        let ticket = { products: [], delivery: {}, total: { ht: 0, vat: 0, ttc: 0 } };
        let vat = this.getVatRate(infos);
        // Create Products
        cart.map((item) => {
            // Save Metadata
            let metadata = JSON.parse(JSON.stringify(item));
            delete metadata.snapshot;
            let ht = item.quantity * Price.prototype.getPrice(item);
            let it = {
                metadata: metadata,
                quantity: item.quantity,
                unitprice: Price.prototype.getPrice(item).toFixed(2),
                ht: ht.toFixed(2),
                vat: (ht * vat).toFixed(2),
                ttc: (ht * (1 + vat)).toFixed(2)
            };
            ticket.total.ht += ht;
            ticket.total.vat += ht * vat;
            ticket.total.ttc += ht * (1 + vat);
            ticket.products.push(it);
            return null;
        });
        let ht = Price.prototype.getDelivery(cart);
        ticket.delivery = { quantity: 1, ht: ht.toFixed(2), vat: (ht * vat).toFixed(2), ttc: (ht * (1 + vat)).toFixed(2) };
        ticket.total.ht = (ticket.total.ht + ht).toFixed(2);
        ticket.total.vat = (ticket.total.vat + ht * vat).toFixed(2);
        ticket.total.ttc = (ticket.total.ttc + ht * (1 + vat)).toFixed(2);
        return ticket;
    }

    /**
     * Ticket Object with everything needed
     */
    getVatRate(infos) {
        let vat = 0;
        // France or not VAT number, apply French VAT
        if (!(this.isVATNumberApplicable(infos) && infos.vatnumber)) {
            vat = 0.2;
        }
        return vat;
    }

    /**
     * Ticket Object with everything needed
     */
    isVATNumberApplicable(infos) {
        if (infos.shipping_country !== 'FR' && infos.billing_country !== 'FR') {
            return true;
        }
        return false;
    }

    componentDidMount() {
        var UPDATE_TOPIC = 'update';
        PubSub.subscribe(
            UPDATE_TOPIC,
            function (msg, data) {
                this.setState({ price: this.getPrice(data) });
            }.bind(this)
        );
    }

    render() {
        return <span>{this.state.price}&nbsp;€HT</span>;
    }
}

export default Price;
