import Price from './../components/configurator/price.js';

class Mollie {
    getFactoryOrder() {
        let order_id = localStorage.getItem('mollie_checkoutid');
        if (order_id) {
            return fetch('/.netlify/functions/factory-getorder', { headers: { 'plato-orderid': order_id } })
                .then((response) => response.json())
                .then((resultData) => {
                    return resultData;
                });
        }
    }

    getOrder() {
        let order_id = localStorage.getItem('mollie_checkoutid');
        if (order_id) {
            return fetch('/.netlify/functions/cart-getorder', { headers: { 'plato-orderid': order_id } })
                .then((response) => response.json())
                .then((resultData) => {
                    return resultData;
                });
        }
    }

    getMethods(cart, infos) {
        let ticket = Price.prototype.getTicket(cart, infos);
        let amount = ticket.total.ttc;
        return fetch('/.netlify/functions/cart-getmethods', {
            method: 'POST',
            body: JSON.stringify({
                resource: 'orders',
                sequenceType: 'oneoff',
                amount: {
                    currency: 'EUR',
                    value: amount
                }
            })
        })
            .then((response) => response.json())
            .then((resultData) => {
                if (resultData) {
                    return resultData;
                }
                return null;
            })
            .catch(function (error) {
                return null;
            });
    }

    newOrder(cart, infos) {
        let mollie_items = [];
        let ticket = Price.prototype.getTicket(cart, infos);
        let vatr = Price.prototype.getVatRate(infos);
        let vatRate = (vatr * 100).toFixed(2);
        ticket.products.map(function (item) {
            mollie_items.push({
                type: 'physical',
                sku: 'plato_table_2020_custom',
                name: 'Plato Custom Table',
                quantity: item.quantity,
                vatRate: vatRate,
                unitPrice: {
                    currency: 'EUR',
                    value: (item.unitprice * (1.0 + vatr)).toFixed(2)
                },
                totalAmount: {
                    currency: 'EUR',
                    value: item.ttc
                },
                vatAmount: {
                    currency: 'EUR',
                    value: item.vat
                },
                metadata: item.metadata
            });
            return null;
        });
        mollie_items.push({
            type: 'shipping_fee',
            sku: 'plato_delivery',
            name: 'Plato Delivery',
            quantity: 1,
            vatRate: vatRate,
            unitPrice: {
                currency: 'EUR',
                value: ticket.delivery.ttc
            },
            totalAmount: {
                currency: 'EUR',
                value: ticket.delivery.ttc
            },
            vatAmount: {
                currency: 'EUR',
                value: ticket.delivery.vat
            },
            metadata: {}
        });

        let orderid = new Date();
        orderid = orderid.toISOString().slice(0, 16).replace(/-|T|:/g, '');

        let mollie_cart = {
            amount: {
                currency: 'EUR',
                value: ticket.total.ttc
            },
            metadata: infos,
            orderNumber: orderid,
            lines: mollie_items,
            billingAddress: {
                givenName: infos.givenName,
                familyName: infos.familyName,
                organizationName: infos.billing_companyName,
                email: infos.email,
                streetAndNumber: infos.billing_streetAndNumber,
                postalCode: infos.billing_postalCode,
                city: infos.billing_city,
                country: infos.billing_country
            },
            shippingAddress: {
                givenName: infos.givenName,
                familyName: infos.familyName,
                organizationName: infos.shipping_companyName,
                email: infos.email,
                streetAndNumber: infos.shipping_streetAndNumber,
                postalCode: infos.shipping_postalCode,
                city: infos.shipping_city,
                country: infos.shipping_country
            },
            locale: window.navigator.userLanguage || window.navigator.language || navigator.browserLanguage || 'fr_FR',
            redirectUrl: 'https://' + window.location.hostname + '/order',
            method: infos.method
        };

        return fetch('/.netlify/functions/cart-neworder', {
            method: 'POST',
            body: JSON.stringify(mollie_cart)
        })
            .then((response) => response.json())
            .then((resultData) => {
                if (resultData.order && resultData.order._links && resultData.order._links.checkout) {
                    localStorage.setItem('mollie_checkoutid', resultData.order.id);
                    return resultData.order._links.checkout;
                }
                return null;
            })
            .catch(function (error) {
                return null;
            });
    }
}

export default Mollie;
